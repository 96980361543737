<template>
  <div class="col text-center my-4">
    <router-link :to="{ name: 'index' }">
      <img height="150" src="https://www.draiai.com/images/index/kv/logo.svg" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>
